import * as React from 'react';

import {
  container
} from './layout.module.scss';

const Layout = ({ pageTitle, children }) => {
  return (
    <main className={container}>
      <title>{pageTitle}</title>
      {children}
    </main>
  )
}

export default Layout;